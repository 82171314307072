import React, { Component } from 'react'
import SeoHead from 'Components/SeoHead'
import Layout from 'Components/Layout'
import AppActions from 'AppActions'
import { navigate } from 'gatsby'
import AppConstants from 'AppConstants'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import LazyLoad from 'react-lazyload'
import scrolltop from 'simple-scrolltop'
import { forceCheck } from 'react-lazyload'
import { CSSTransition } from 'react-transition-group'
import uiManager from 'Components/Utils/uiManager'
// import InternalPageMessage from 'Components/UI/InternalPageMessage'
// import styles from './index.module.scss'

class EditorialPage extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.breadcrumbsData = [
      {
        path: `/edit`,
        title: `edit`,
        disable: true
      },
      {
        path: `/edit/${this.props.pageContext.slug}`,
        title: this.props.pageContext.title,
        disable: true
      }
    ]
    const comps = this.props.pageContext.components
    this.components = comps.map((component) => {
      return {
        type: component.type,
        params: component.params,
        components: component.components
      }
    })
    this.uiManager = new uiManager(this.components)
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    setTimeout(forceCheck, 100)
    this.checkAnchorsHash()
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    setTimeout(forceCheck, 100)
  }
  checkAnchorsHash = () => {
    const { location } = this.props
    if (location.hash) {
      const { hash } = location
      setTimeout(() => {
        navigate(`${location.pathname}${hash}`)
      }, 0)
    }
  }
  render() {
    const components = this.uiManager.renderComponents()
    const wrappedComponents = components.map((item, index) => {
      if (item.props.type && item.props.type === 'text') {
        return <div key={`editorial-component--${index}`}>{item}</div>
      } else {
        return (
          <div key={`editorial-component--${index}`}>
            <LazyLoad height={400} offset={0} once>
              <CSSTransition
                timeout={800}
                in={true}
                appear={true}
                enter={false}
                exit={false}
                classNames={{
                  appear: 'u-fade-appear',
                  appearActive: 'u-fade-appear-active'
                }}>
                {item}
              </CSSTransition>
            </LazyLoad>
          </div>
        )
      }
    })
    const backgroundColor = this.props.pageContext.backgroundColor
    return (
      <Layout>
        <SeoHead
          title={`${this.props.pageContext.title}`}
          description={`${this.props.pageContext.description}`}
          path={`/edit/${this.props.pageContext.slug}`}
        />
        { this.props.pageContext.enableBreadcrumbs && <Breadcrumbs items={this.breadcrumbsData}/>}
        <div ref={this.parent} style={{backgroundColor}}>{ wrappedComponents }</div>
      </Layout>
    )
  }
}

export default EditorialPage
